/* Most CSS rules are in JS components */
html {
  font-family:
    'Roboto',
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
a {
  color:#02346d;
}
a:active, a:visited {
  color:#636;
}
.mapboxgl-popup-content > .popup > * {
  margin: 0;
}
